import React from "react";
import { Divider, Paper, Typography } from "@material-ui/core";
import Image from "next/image";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import renderHTML from "lib/render-html";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    mode: {
      "& span": {
        color: theme.palette.primary.main,
        fontStyle: "italic",
      },
    },
  })
);
const TrainingDeliveryModeCardComponent: React.FC<
  TrainingDeliveryModeCardProps
> = ({ image_url, text, mode }) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.root}>
      <Image
        src={image_url}
        style={{ width: "100%", height: "auto" }}
        width={640}
        height={498}
        alt="classroom"
      />
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        className={classes.mode}
      >
        {renderHTML(mode)}
      </Typography>
      <Divider variant="middle" />
      <Typography align="center">{text}</Typography>
    </Paper>
  );
};
const TrainingDeliveryModeCard = React.memo(TrainingDeliveryModeCardComponent);
export default TrainingDeliveryModeCard;
